import { ContainerXl } from '/features/buildingBlocks/Container'
import { HeadingCondensedLg } from '/features/buildingBlocks/Heading'

import styles from './FormSection.css'

export function FormSection({
  children,
  dataX,
  onSubmit,
  title,
  styleContext,
  id = undefined,
  renderButton = undefined,
  onChange = undefined,
  layoutClassName = undefined,
}) {
  return (
    <section data-x={dataX} data-style-context={styleContext} className={cx(styles.component, layoutClassName)}>
      <ContainerXl>
        <div className={styles.formSection}>
          <div className={styles.formSectionTitleContainer}>
            <HeadingCondensedLg h={3} {...{ title }} />
            {renderButton && renderButton()}
          </div>

          <form className={styles.formContainer} {...{ id, onSubmit, onChange }}>
            {children}
          </form>
        </div>
      </ContainerXl>
    </section>
  )
}
