import { ContainerXl } from '/features/buildingBlocks/Container'
import { ContentColumns } from '/features/buildingBlocks/ContentColumns'
import { HeadingCondensedMd } from '/features/buildingBlocks/Heading'

import styles from './FormSuccess.css'

export function FormSuccess({ title, description, id = undefined, styleContext = undefined }) {
  return (
    <div data-style-context={styleContext} className={styles.component} {...{ id }}>
      <ContainerXl>
        <ContentColumns
          renderSmallColumn={({ layoutClassName }) => <Title {...{ title, layoutClassName }} />}
          renderLargeColumn={({ layoutClassName }) => <Description {...{ description, layoutClassName }} />}
        />
      </ContainerXl>
    </div>
  )
}

function Title({ title, layoutClassName }) {
  return (
    <div className={cx(styles.componentTitle, layoutClassName)}>
      <HeadingCondensedMd h={3} {...{ title }} />
    </div>
  )
}

function Description({ description, layoutClassName }) {
  return <p className={cx(styles.componentDescription, layoutClassName)}>{ description }</p>
}
