import styles from './ContentColumns.css'

export function ContentColumns({ renderLargeColumn, renderSmallColumn = undefined }) {
  return (
    <div className={styles.component}>
      {renderSmallColumn && renderSmallColumn({ layoutClassName: styles.smallColumnLayout })}
      {renderLargeColumn({ layoutClassName: styles.largeColumnLayout })}
    </div>
  )
}
