import { useFormField, useBooleanFormField } from '@kaliber/forms'

import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Input.css'

import iconPlus from '/images/icons/plus.raw.svg'
import iconMark from '/images/icons/mark.raw.svg'
import iconChevronDown from '/images/icons/chevron-down.raw.svg'

export function InputText({ field, type, placeholder, layoutClassName }) {
  const { name, state, eventHandlers } = useFormField(field)

  return (
    <input
      id={name}
      aria-describedby={`${name}-description ${name}-error`}
      value={state.value}
      className={cx(styles.componentText, layoutClassName)}
      {...{ type, placeholder }}
      {...eventHandlers}
    />
  )
}

export function InputNumber({ field, placeholder, layoutClassName }) {
  const { name, state, eventHandlers: { onChange, ...eventHandlers } } = useFormField(field)

  return (
    <input
      id={name}
      aria-describedby={`${name}-description ${name}-error`}
      value={state.value}
      inputMode='numeric'
      onChange={handleChange}
      className={cx(styles.componentNumber, layoutClassName)}
      {...{ placeholder }}
      {...eventHandlers}
    />
  )

  function handleChange(e) {
    const value = e.target.value
    if (!value || value === String(Number(value))) onChange(value)
  }
}

export function InputTextarea({ field, maxLength, placeholder }) {
  const { name, state, eventHandlers } = useFormField(field)

  return (
    <div className={styles.componentTextarea}>
      <textarea
        id={name}
        aria-describedby={`${name}-description ${name}-error`}
        value={state.value}
        className={styles.textArea}
        {...{ maxLength, placeholder }}
        {...eventHandlers}
      />
    </div>
  )
}

export function InputSelect({ field, options, placeholder = undefined }) {
  const { name, state, eventHandlers } = useFormField(field)
  const showPlaceholder = !state.value && placeholder

  return (
    <div className={styles.componentSelect}>
      <select
        value={state.value}
        aria-describedby={`${name}-description`}
        className={styles.select}
        {...eventHandlers}
      >
        {showPlaceholder && <option key='' value=''>{placeholder}</option>}

        {options.map((option, i) => (
          <option key={i} value={option.value}>{option.label}</option>
        ))}
      </select>

      <Icon icon={iconChevronDown} layoutClassName={styles.iconLayout} />
    </div>
  )
}

export function InputCheckbox({ field, required, children }) {
  const { name, state, eventHandlers } = useBooleanFormField(field)
  const { value } = state

  return (
    <div className={styles.componentCheckbox}>
      <input
        id={name}
        aria-labelledby={`${name}-label`}
        className={cx(styles.inputCheckbox, value && styles.checked)}
        type="checkbox"
        checked={value}
        {...{ value, name, required }}
        {...eventHandlers}
      />
      <label id={`${name}-label`} htmlFor={name} className={styles.labelCheckbox}>
        {children}{required && '*'}
      </label>
    </div>
  )
}

export function InputCheckBoxWithValue({ field, required, children, instanceValue }) {
  const { name, state, eventHandlers: { onChange, ...eventHandlers } } = useFormField(field)
  const { value } = state

  return (
    <div className={styles.componentCheckBoxWithValue}>
      <input
        id={name}
        aria-labelledby={`${name}-label`}
        className={cx(styles.inputCheckbox, value && styles.checked)}
        type="checkbox"
        checked={value === instanceValue}
        {...{ value, name, required }}
        {...eventHandlers}
        onChange={handleChange}
      />
      <label id={`${name}-label`} htmlFor={name} className={styles.labelCheckbox}>
        {children}{required && '*'}
      </label>
    </div>
  )

  function handleChange() {
    if (instanceValue === value) {
      onChange(null)
    } else {
      onChange(instanceValue)
    }
  }
}

export function InputFile({ field, placeholder, label, required }) {
  const { name, state, eventHandlers: { onChange, ...eventHandlers } } = useFormField(field)
  const { value  } = state

  return (
    <div className={styles.componentFile}>
      {value ? (
        <button
          aria-labelledby={`${name}-label`}
          type='button'
          onClick={_ => onChange(null)}
          data-x='remove-file'
          className={styles.fileButton}
        >
          <span className={styles.icon}><Icon icon={iconMark} /></span>
          <span>{value.name}{required && '*'}</span>
        </button>
      ) : (
        <div className={styles.fileInputContainer}>
          <input
            id={name}
            type='file'
            accept=".pdf, .docx"
            onChange={e => {
              eventHandlers.onBlur(e)
              onChange(e.currentTarget.files[0])
            }}
            className={styles.fileInput}
            {...{ name, required }}
            {...eventHandlers}
          />
          <label htmlFor={name} data-x='select-file' className={styles.fileInputLabel}>
            <span className={styles.icon}><Icon icon={iconPlus} /></span>
            <span>{placeholder}{required && '*'}</span>
          </label>
        </div>
      )}
    </div>
  )
}
