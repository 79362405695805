import { Icon } from '/features/buildingBlocks/Icon'

import styles from './FormError.css'

import warningIcon from '/images/icons/warning.raw.svg'

export function FormError({ id, errorMessage }) {
  return (
    <div className={styles.component} {...{ id }}>
      <Icon icon={warningIcon} layoutClassName={styles.iconLayout} />
      <span>{errorMessage}</span>
    </div>
  )
}
