import styles from './Heading.css'

export function HeadingXs({ title, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentXs, layoutClassName)} {...{ h, title }} />
}

export function HeadingSm({ title, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentSm, layoutClassName)} {...{ h, title }} />
}

export function HeadingMd({ title, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentMd, layoutClassName)} {...{ title, h }} />
}

export function HeadingLg({ title, h, layoutClassName = undefined }) {
  return <HeadingBase className={cx(styles.componentLg, layoutClassName)} {...{ h, title }} />
}

export function HeadingCondensedSm({ title, h, centered = false, layoutClassName = undefined }) {
  return <HeadingCondensedBase className={cx(styles.componentCondensedSm, layoutClassName)} {...{ h, title, centered }} />
}

export function HeadingCondensedMd({ title, h, centered = false, layoutClassName = undefined }) {
  return <HeadingCondensedBase className={cx(styles.componentCondensedMd, layoutClassName)} {...{ h, title, centered }} />
}

export function HeadingCondensedLg({ title, h, centered = false, layoutClassName = undefined }) {
  return <HeadingCondensedBase className={cx(styles.componentCondensedLg, layoutClassName)} {...{ h, title, centered }} />
}

export function HeadingCondensedXl({ title, h, centered = false, layoutClassName = undefined }) {
  return <HeadingCondensedBase className={cx(styles.componentCondensedXl, layoutClassName)} {...{ h, title, centered }} />
}

export function HeadingCondensedXxl({ title, h, centered = false, layoutClassName = undefined }) {
  return <HeadingCondensedBase className={cx(styles.componentCondensedXxl, layoutClassName)} {...{ h, title, centered }} />
}

function HeadingCondensedBase({ title, h, centered, className }) {
  return <HeadingBase className={cx(styles.componentCondensedBase, className)} {...{ h, title, centered }} />
}

function HeadingBase({ title, h, className, centered = false }) {
  /** @type {any} */
  const HBase = `h${h}`

  return <HBase className={cx(styles.componentBase, centered && styles.centered, className)}>{title}</HBase>
}
