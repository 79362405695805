import { useFormField } from '@kaliber/forms'

import { useTranslate } from '/machinery/I18n'

import { InputText, InputNumber, InputTextarea, InputSelect, InputCheckbox, InputFile, InputLabelCheckbox, InputCheckBoxWithValue } from '/features/buildingBlocks/Input'
import { FormError } from '/features/buildingBlocks/FormError'

import styles from './FormField.css'

export function FormFieldRow({ children }) {
  return <div className={styles.componentRow}>{children}</div>
}

export function FormFieldText({ field, label, placeholder = undefined, description = undefined, required = undefined }) {
  return (
    <FormFieldBase className={styles.componentText} {...{ field, label, placeholder, description, required }}>
      <InputText type='text' layoutClassName={styles.inputLayout} {...{ field, placeholder }} />
    </FormFieldBase>
  )
}

export function FormFieldNumber({ field, label, placeholder = undefined, description = undefined, required = undefined }) {
  return (
    <FormFieldBase className={styles.componentNumber} {...{ field, label, placeholder, description, required }}>
      <InputNumber layoutClassName={styles.inputLayout} {...{ field, placeholder }} />
    </FormFieldBase>
  )
}

export function FormFieldEmail({ field, label, placeholder = undefined, description = undefined, required = undefined }) {
  return (
    <FormFieldBase className={styles.componentEmail} {...{ field, label, placeholder, description, required }}>
      <InputText type='email' layoutClassName={styles.inputLayout} {...{ field, placeholder }} />
    </FormFieldBase>
  )
}

export function FormFieldTextarea({ field, label, maxLength = undefined, placeholder = undefined, description = undefined, required = undefined }) {
  return (
    <FormFieldBase className={styles.componentTextarea} {...{ field, label, placeholder, description, required }}>
      <InputTextarea {...{ field, maxLength, placeholder }} />
    </FormFieldBase>
  )
}

export function FormFieldSelect({ field, label, options, placeholder = undefined, description = undefined, required = undefined }) {
  return (
    <FormFieldBase className={styles.componentSelect} {...{ field, label, placeholder, description, required }}>
      <InputSelect {...{ field, placeholder, options }} />
    </FormFieldBase>
  )
}

export function FormFieldCheckbox({ field, children, required = undefined }) {
  return (
    <FormFieldBase className={styles.componentCheckbox} {...{ field, required }}>
      <InputCheckbox {...{ field, required, children }} />
    </FormFieldBase>
  )
}

export function FormFieldCheckBoxWithValue({ field, children, instanceValue, required = undefined }) {
  return (
    <FormFieldBase className={styles.componentCheckBoxWithValue} {...{ field, required }} >
      <InputCheckBoxWithValue {...{ field, required, children, instanceValue }} />
    </FormFieldBase>
  )
}

export function FormFieldFile({ field, label, placeholder, required = undefined }) {
  return (
    <FormFieldBase className={styles.componentFile} {...{ field, label, required }}>
      <InputFile {...{ field, label, placeholder, required }} />
    </FormFieldBase>
  )
}

function FormFieldBase({ className, field, children, label = undefined, description = undefined, required = undefined }) {
  const { name, state } = useFormField(field)
  const { error, showError } = state
  const errorMessage = useErrorMessage(error)

  return (
    <div className={cx(styles.componentBase, className)}>
      {label && <Label htmlFor={name} {...{ label, required }} />}

      {children}

      {description && <Description id={`${name}-description`} {...{ name, description }} />}

      {showError && <FormError id={`${name}-text-field-error`} {...{ errorMessage }} />}
    </div>
  )
}

function Label({ label, htmlFor, required = undefined, layoutClassName = undefined }) {
  return (
    <label className={cx(styles.componentLabel, layoutClassName)} {...{ htmlFor }}>
      {label} {required && '*'}
    </label>
  )
}

function Description({ id, description }) {
  return <span className={styles.componentDescription} {...{ id }}>{description}</span>
}

function useErrorMessage(error) {
  const { __ } = useTranslate()

  const errorMessages = {
    required: __`form-validation-required`,
    requiredTrue: __`form-validation-required`,
    email: __`form-validation-email`,
    number: __`form-validation-number`,
    maxFileSize: x => __({ fileSize: bytesToMb(x) })`field-validation-max-fileSize`,
    minFileSize: () => __`field-validation-min-fileSize`,
    fileExtension: x => {
      return __({ fileExtensions: readable(x) })`field-validation-fileExtension`

      function readable(array) {
        const [last, ...rest] = array.slice().reverse()
        return `${rest.reverse().join(', ')} or ${last}`
      }
    }
  }

  return errorMessages[error.id]
}

function bytesToMb(bytes) {
  return bytes / (1000 * 1000)
}
